import React, {useState} from 'react';
import './App.css';
import {TextField} from "@material-ui/core";

function App() {
    const [groovyText, setGroovyText] = useState("")
    const [kotlinText, setKotlinText] = useState("")
    const [groovyActive, setGroovyActive] = useState(false)
    const [kotlinActive, setKotlinActive] = useState(false)

    return <div className="App">
        <TextField
            label="Groovy Dependency"
            variant="outlined"
            value={(!groovyActive && kotlinText && formatFromKotlinToGroovy(kotlinText)) || groovyText}
            onChange={event => {
                setGroovyActive(true)
                setKotlinActive(false)
                setGroovyText(event.target.value);
            }}
        />
        <TextField
            label="Kotlin Dependency"
            variant="outlined"
            value={(!kotlinActive && groovyText && formatFromGroovyToKotlin(groovyText)) || kotlinText}
            onChange={event => {
                setGroovyActive(false)
                setKotlinActive(true)
                setKotlinText(event.target.value);
            }}
        />
    </div>;
}

function formatFromKotlinToGroovy(kotlinText: string): string {
    return kotlinText;
}

function formatFromGroovyToKotlin(groovyText: string): string {
    const regex = /^\s*group:\s*['"](?<group>[_.\w\d]+)['"]\s*,\s*name:\s*['"](?<name>[_.\w\d]+)['"]\s*,\s*version:\s*['"](?<version>[_.\w\d]+)['"]\s*$/;
    if (!regex.test(groovyText)) {
        return ""
    }
    const exec = regex.exec(groovyText);
    const group = exec?.groups?.group
    const name = exec?.groups?.name
    const version = exec?.groups?.version

    return `group="${group}", name="${name}", version="${version}"`
}

export default App;
